import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormDto} from 'src/app/data/objects/form.dto';
import {SheetRepository} from "../../data/repository/sheet.repository";
import {Router} from "@angular/router";

@Component({
  selector: 'app-nav-forms',
  templateUrl: './nav-forms.component.html',
  styleUrls: ['./nav-forms.component.scss']
})
export class NavFormsComponent implements OnChanges {
  @Input() showTitles: boolean = true;
  @Input() refresh: boolean;
  forms: FormDto[] | undefined;

  constructor(private sheetRepo: SheetRepository,
              private router: Router) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    await this.getForms();
  }

  async getForms() {

  }

}
