@for (item of folder.children; track item.type + item.id; let item_index = $index) {

  <div class="tree" [class.height-36px]="item.type=='sheet' || item.type =='page'"
       [ngClass]="isColapsed?'colapsed':''">

    <!-- folder section -->
    @if (item.type == 'folder') {
      <div>
        <!-- current folder -->
        <div #dropdown="ngbDropdown" ngbDropdown container="body" placement="bottom-end">
          <div (contextmenu)="openContextMenu(item,dropdown)" ngbDropdownAnchor class="folder"
               [ngClass]="!showTitles?'nav-collapsed-folder':''"
               [style.padding-inline-start]="showTitles?(item.indentPx)+'px':''"
               (click)="item.colapsed=!item.colapsed">

            <div class="item-left">
              @if (item.indentPx > indentPx && showTitles) {
                <div class="divider"></div>
              }
              <i class="bi toggle-icon hover-opacity"
                 [ngClass]="{'toggle-expanded':!item.colapsed && dir === 'ltr','toggle-expanded-rtl':!item.colapsed && dir === 'rtl','bi-caret-right-fill':dir === 'ltr' ,'bi-caret-left-fill':dir==='rtl'}"></i>

              <icon [(icon)]="item.icon" size="xlarge"
                    [pickable]="item.renaming!!"
                    class="item-icon" [ngbTooltip]="!showTitles?item.name:''"
                    container="body"></icon>
              <span class="item-name" [style.max-width.px]="150-item.indentPx"
                    [class.bold-title]="!item.colapsed"
                    [hidden]="item.renaming || !showTitles">{{ item.name }}
                    </span>
              <div class="rename-holder" [hidden]="!item.renaming">
                <input class="rename-input" type="text" (keyup.esc)="item.renaming = false"
                       (keyup.enter)="onRenameSubmit(item)" (click)="$event.stopPropagation()"
                       [(ngModel)]="item.name">
                <icon icon="bi-check-lg" [hoverable]="true" size="xlarge" color="green"
                      class="h-100"
                      (click)="onRenameSubmit(item);$event.stopPropagation()"></icon>
              </div>

            </div>

            <div class="item-right"
                 [hidden]="!showTitles || item.renaming || !(item.editable ?? true)">
              <div class="d-flex" style="padding: 5px; column-gap: 8px">

                <icon
                  *ngIf="item.canEdit"
                  icon="bi-three-dots-vertical"
                  size="large"
                  [hoverable]="true"
                  (click)="dropdown.open(); $event.stopPropagation()"
                />
              </div>
            </div>
          </div>
          <div ngbDropdownMenu>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="onCreateSheetClick(item); dropdown.close()"
            >
              <icon icon="bi-journal-plus"/>
              {{ 'ADD_NEW_WORKSHEET' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="onCreateFolderClick(item); dropdown.close()"
            >
              <icon icon="bi-folder-plus"/>
              {{ 'ADD_SUB_FOLDER' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="onCreateTaskSheetClick(item); dropdown.close()"
            >
              <icon icon="bi-list-task"/>
              {{ 'ADD_NEW_TASKSHEET' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="onCreatePageClick(item); dropdown.close()"
            >
              <icon icon="bi-blockquote-left"></icon>
              {{ 'new_page' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="importCsvFile(item); dropdown.close()"
            >
              <icon icon="bi-file-earmark-arrow-down"></icon>
              {{ 'IMPORT_CSV' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="item.renaming = true; dropdown.close()"
            >
              <icon icon="bi-pencil"></icon>
              {{ 'RENAME' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canShare"
              class="dropdownitem"
              (click)="showPermissionDialog(item); dropdown.close()"
            >
              <icon icon="bi-shield-lock"></icon>
              {{ 'PERMISSIONS' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canShare && environment.exportDomains.includes(domain||'')"
              class="dropdownitem"
              (click)="showExportDialog(item); dropdown.close()"
            >
              <icon icon="bi-box-arrow-up"></icon>
              {{ 'EXPORT' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canDelete"
              class="dropdownitem"
              (click)="item.deleting = true"
            >
              <icon icon="bi-folder-x"></icon>
              {{ 'delete' | translate }}
            </div>
            <div *ngIf="item.deleting" class="confirm-delete-holder">
              <div class="confirm-title">{{ 'areYouSure?' | translate }}</div>
              <div class="confirm-delete-button-holder">
            <span
              class="confirm-yes"
              (click)="item.deleting = false; dropdown.close(); deleteItem(item, item_index)"
            >{{ 'delete' | translate }}</span>
                <span class="confirm-no"
                      (click)="item.deleting = false; dropdown.close()">{{ 'cancel' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- nested folders -->
        <app-tree-folder
          *ngIf="item.children.length > 0"
          [folder]="item"
          [currentItem]="currentItem"
          [isColapsed]="item.colapsed"
          [showTitles]="showTitles"
          [currentSpaceId]="currentSpaceId"
          (onMove)="onItemMove($event)"
        />
      </div>
    }

    <!-- items section -->
    @if (item.type == 'sheet' || item.type == 'page') {
      <div
        style="width: 100%;"
        #dropdown="ngbDropdown"
        container="body"
        ngbDropdown
        placement="end"
        class="d-inline-block"
      >
        <div
          #itemHolder="routerLinkActive"
          ngbDropdownAnchor
          (contextmenu)="openContextMenu(item, dropdown)"
          class="item"
          id="item_{{item.id}}"
          [ngClass]="!showTitles ? 'nav-collapsed-item' : ''"
          [style.padding-inline-start]="showTitles ? item.indentPx + 'px' : ''"
          [class.active]="item.id==currentItem"
          routerLinkActive="active"
          [routerLink]="item.type=='sheet'?'/panel/space/'+currentSpaceId +'/sheet/'+ item.id :'/panel/space/'+currentSpaceId+'/page/'+item.id"
        >
          <div class="item-left">
            @if (item.indentPx > indentPx && showTitles) {
              <!--              <div class="divider" [style.opacity]="itemHolder.isActive?0:1"></div>-->
              <div class="divider" [style.margin-inline-end]="itemHolder.isActive?0:'8px'"></div>
              @if (itemHolder.isActive) {
                <div class="divider-active">●</div>
              }
            }
            <icon
              class="item-icon"
              [pickable]="item.renaming!!"
              size="xlarge"
              [(icon)]="item.icon"
              [ngbTooltip]="!showTitles ? item.name : ''"
              container="body"
            />

            <span
              class="item-name"
              [style.max-width.px]="220 - item.indentPx"
              [hidden]="item.renaming || !showTitles"
            >{{ item.name }}</span>
            <div class="rename-holder" [hidden]="!item.renaming">
              <input
                class="rename-input"
                type="text"
                (keyup.esc)="item.renaming = false"
                (keyup.enter)="onRenameSubmit(item)"
                (click)="$event.stopPropagation()"
                [(ngModel)]="item.name"
              />
              <icon
                icon="bi-check-lg"
                size="xlarge"
                color="green"
                class="h-100"
                [hoverable]="true"
                (click)="onRenameSubmit(item); $event.stopPropagation()"
              ></icon>
            </div>
          </div>
          <div class="item-right" [hidden]="!showTitles || item.renaming">
            <icon
              icon="bi-three-dots-vertical"
              [hoverable]="true"
              size="large"
              *ngIf="item.canEdit"
              (click)="dropdown.open(); $event.stopPropagation()"
            />
          </div>
        </div>
        <div *ngIf="item.editable" ngbDropdownMenu>
          <div
            *ngIf="!item.deleting && item.canEdit"
            class="dropdownitem"
            (click)="item.renaming = true; dropdown.close()"
          >
            <icon icon="bi-pencil"></icon>
            {{ 'RENAME' | translate }}
          </div>
          <div
            *ngIf="!item.deleting && item.canShare"
            class="dropdownitem"
            (click)="showPermissionDialog(item); dropdown.close()"
          >
            <icon icon="bi-shield-lock"/>
            {{ 'PERMISSIONS' | translate }}
          </div>
          <div
            *ngIf="!item.deleting && item.canShare && environment.exportDomains.includes(domain||'')"
            class="dropdownitem"
            (click)="showExportDialog(item); dropdown.close()"
          >
            <icon icon="bi-box-arrow-up"/>
            {{ 'EXPORT' | translate }}
          </div>

          @if (!item.deleting && item.canDelete) {
            <div class="dropdownitem" ngbDropdown placement="end" #moveDrop="ngbDropdown"
                 container="body">
              <div class="d-flex justify-content-between w-100" ngbDropdownAnchor
                   (mouseenter)="moveDrop.open()">
                <div>
                  <icon icon="bi-folder-symlink"/>
                  {{ 'move'|translate }}
                </div>
                <icon icon="bi-chevron-{{dir=='rtl'?'left':'right'}}" size="xsmall"/>
              </div>
              <div ngbDropdownMenu>
                <atw-tree-navigator [root]="{id:currentSpaceId,type:EntityType.SPACE}"
                                    (select)="moveItem(item_index,item,$event)" [showItems]="false"/>
              </div>
            </div>
          }
          <div
            *ngIf="!item.deleting && item.canDelete"
            class="dropdownitem"
            (click)="item.deleting = true"
          >
            <icon icon="bi-journal-x"></icon>
            {{ 'delete' | translate }}
          </div>
          <div *ngIf="item.deleting" class="confirm-delete-holder">
            <div class="confirm-title">{{ 'areYouSure?' | translate }}</div>
            <div class="confirm-delete-button-holder">
          <span
            class="confirm-yes"
            (click)="item.deleting = false; dropdown.close(); deleteItem(item, item_index)"
          >{{ 'delete' | translate }}</span
          >
              <span class="confirm-no"
                    (click)="item.deleting = false; dropdown.close()">{{ 'cancel' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    }

  </div>
}
